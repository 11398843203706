@import url('https://fonts.googleapis.com/css2?family=The+Girl+Next+Door&display=swap');

.hpContainer {
  margin: 0 auto;
  font-family: 'The Girl Next Door', cursive;
  font-weight: 400;
  font-style: normal;
  margin-top: 2%;
  margin-left: 7%;
  margin-right: 7%;
  text-align: left;
}
